import { useEffect, useState, useRef } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import authAxios from "../../components/authAxios";

import { IconButton } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import DescriptionIcon from '@mui/icons-material/Description';
import Tooltip from '@mui/material/Tooltip';





//TABS - BOOTSTRAP
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


import { Dropdown, Menu, Space } from 'antd';

import DashboardHeader from "../../components/DashboardHeader";
import TransactionList from "../accounting/TransactionList";
import UserAlerts from "../../components/UserAlerts";
import PropertyView from "./PropertyView";
import UnitList from "./UnitList";

const Property = () => {
    const params = useParams()
    const navigate = useNavigate()
    const [error, setError] = useState('');
    const errorRef = useRef(null);

    const propertyId = params.id
    const removeBorder = true
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();

    const { data: clientProfile } = useQuery({
        queryKey: ['clientProfile'],
        queryFn: async () => {
          const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`);
          return response.data

        },
    });

    const { data: transactions } = useQuery({
        queryKey: ['property-ledger', page],
        queryFn: async () => {
            const response = await authAxios.get(`/rentals/property-ledger/?property=${propertyId}&page=${page}`);
            setPageCount(response.data.page_count)
            return response.data.results
        },
    });

   
    const { data: property } = useQuery({
        queryKey: ['property',propertyId],
        queryFn: async () => {
          const response = await authAxios.get(`rentals/properties/${propertyId}`);
          return response.data
        },
    });

   
    const menu = (
        <Menu>
            <Menu.Item onClick={()=>navigate("/dashboard/bulk-create-meters",{state:{property}})}>
                <a>
                    Bulk Create Utility Meters
                </a>
            </Menu.Item>
            <Menu.Item onClick={()=>navigate("/dashboard/upload-property-data",{state:{property}})}>
                <a>
                    Upload Data
                </a>
            </Menu.Item>
            <Menu.Divider />

            <Menu.Item onClick={()=>navigate("/dashboard/collection-report",{state:{property}})}>
                <a>
                    Collection Report
                </a>
            </Menu.Item>
            <Menu.Item onClick={()=>navigate("/dashboard/owner-statement",{state:{property}})}>
                <a>
                    Owner Statement
                </a>
            </Menu.Item>
            <Menu.Item onClick={()=>navigate("/dashboard/new-owner-statement",{state:{property}})}>
                <a>
                    New Owner Statement
                </a>
            </Menu.Item>
            <Menu.Item disabled>
                <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
                    Other Items
                </a>
            </Menu.Item>
        </Menu>
    );
        

    //Bootstrap Tabs
    const [key, setKey] = useState('profile');
    const handleTabChange = (k) => {
        setKey(k)
    };

    // PAGINATION
    const handlePageChange = (event, value) => {
        setPage(value);
    };

    useEffect(()=>{
        if (error){
            errorRef.current.scrollIntoView();  
        }            
    },[error])

    return (
        <div className="dashboard-main">
            {property &&

            <section className="dashboard-content dashboard-content-compact">
                 <div ref={errorRef}>
                    <UserAlerts error={error} />
                </div>

                <div className="dashboard-content-header-nav">
                    {/* <DashboardHeader dashboardTitle={formatReference(property.reference)} dashboardPreTitle="Property" removeBorder={removeBorder} /> */}
                    <DashboardHeader dashboardTitle={property.name} dashboardPreTitle="Property" removeBorder={removeBorder} />

                    <div className="dashboard-content-header-nav-icons">
                       
                        <Dropdown overlay={menu} placement="bottomRight" arrow>
                            <a onClick={e => e.preventDefault()}>
                            <Space>
                                <IconButton 
                                > 
                                    <MenuIcon />

                                </IconButton>

                            </Space>
                            </a>
                        </Dropdown>
                    </div>
                </div>


              
                <Tabs
                    activeKey={key}
                    onSelect={(k) => handleTabChange(k)}
                    className="dashboard-tabs"
                    id="property-tabs"
                    mountOnEnter={true}
                    unmountOnExit={true}
                    transition={false}
                    >
                    
                    <Tab eventKey="profile" title="Profile">
                        <PropertyView 
                            property={property}
                            setError={setError}
                        />
                    </Tab>

                    <Tab eventKey="units" title="Units">
                        <UnitList 
                            property={property} 
                            // units={units} 
                            // handlePageChange={handlePageChange} 
                            // page={page} 
                            // pagecount={pagecount}
                        />
                    </Tab>

                    <Tab eventKey="statement" title="Ledger">
                        {clientProfile &&
                        <div className="dashboard-content-subheader">
                            <div className='dashboard-card'>
                                <div className="dashboard-card-pretitle">
                                    Revenue
                                </div>
                                <div className="dashboard-card-text">
                                    {clientProfile.currency_code} {property.total_revenue.toLocaleString()}

                                </div>
                            </div>
                            <div className='dashboard-card'>
                                <div className="dashboard-card-pretitle">
                                    Debtors
                                </div>
                                <div className="dashboard-card-text">
                                    {clientProfile.currency_code} {property.debtors.toLocaleString()}

                                </div>
                            </div>
                            <div className='dashboard-card'>
                                <div className="dashboard-card-pretitle">
                                    Expenses
                                </div>
                                <div className="dashboard-card-text">
                                    {clientProfile.currency_code} {property.total_expenses.toLocaleString()}

                                </div>
                            </div>
                            <div className='dashboard-card'>
                                <div className="dashboard-card-pretitle">
                                    Income
                                </div>
                                <div className="dashboard-card-text">
                                    {clientProfile.currency_code} {property.income.toLocaleString()}
                                </div>
                            </div>

                        </div>
                        }
                        <TransactionList 
                            module="PMS" 
                            transactions={transactions} 
                            property={property} 
                            handlePageChange={handlePageChange} 
                            page={page} 
                            pagecount={pagecount}
                        />
                    </Tab>

                </Tabs> 


            </section>
            }

        </div>
    );
  };
  
  export default Property;