
// Version 1
// This function checks if the input (error from API) is an array. 
// If the error response is an array, it returns the first element.
// If the input is not an array, it assumes it's a string and returns it directly.

// const extractErrorMessage = (errorResponse) => {
//     if (Array.isArray(errorResponse)) {
//         return errorResponse[0]; // Return the first error if it's an array
//     }
//     return errorResponse; // Return the error directly if it's a string
// };

// errorUtils.js
export const extractErrorMessage = (data) => {
    const message = data?.error || data?.detail;
    return Array.isArray(message) ? message[0] : message;
};