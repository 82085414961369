import {Routes, Route} from "react-router-dom";


// import Home from "./pages/Home";
import Home from '../pages/home/Home';
import Contact from "../pages/Contact";
import Login from '../pages/Login';
import ResetPassword from '../pages/ResetPassword';
import ForgotPassword from '../pages/ForgotPassword';
// import Join from './pages/join';
import Crm from '../pages/products/Crm';


import Layout from "./Layout";
import Dashboard from "./Dashboard";
import AdminDashboard from "../admin/AdminDashboard";


//CRM|SALES
import ContactNew from './ContactNew';
import Customers from '../features/crm/Customers';
import Customer from '../features/crm/Customer';
import CustomerNew from '../features/crm/CustomerNew';
import CustomerEdit from '../features/crm/CustomerEdit';
import Lead from './Lead';
import Leads from '../features/crm/Leads';
import LeadNew from './LeadNew';


// PURCHASE
import Suppliers from '../features/purchase/Suppliers';
// import SupplierNew from './components/SupplierNew';
import SupplierNew from '../features/purchase/SupplierNew';
import Supplier from '../features/purchase/Supplier';


// ACCOUNTING
import Accounts from '../features/accounting/Accounts';
import AccountNew from '../features/accounting/AccountNew';
import Transactions from '../features/accounting/Transactions';
import TransactionNew from '../features/accounting/TransactionNew'
import Confirmed from './Confirmed'
import Account from '../features/accounting/Account';
import Transaction from '../features/accounting/Transaction';
import Payments from '../features/accounting/Payments';
import Payment from '../features/accounting/Payment';


// PRODUCTS
import Products from '../features/products/Products';
import ProductNew from '../features/products/ProductNew'
import Product from '../features/products/Product';
import PriceLists from '../features/products/PriceLists';
import PriceList from '../features/products/PriceList';
import PriceListItem from '../features/products/PriceListItem';
// import Collections from '../features/products/Collections';
// import Collections from '../features/products/Collections';
// import ProductCategories from '../features/products/ProductCategories';
// import Brands from './features/products/Brands';


// EMPLOYEES
import Employees from '../features/hrm/Employees'
import Employee from '../features/hrm/Employee';
import EmployeeEdit from '../features/hrm/EmployeeEdit';
import ExpenseTypes from '../features/hrm/ExpenseTypes'
import ExpenseTypeNew from '../features/hrm/ExpenseTypeNew'
import ExpenseReports from '../features/hrm/ExpenseReports'
import ExpenseReportNew from '../features/hrm/ExpenseReportNew'
import EmployeeNew from '../features/hrm/EmployeeNew';


// REPORTS
import MonthlyReport from '../reports/MonthlyReport';
import InvoiceReport from '../reports/InvoiceReport';
import IncomeStatement from '../reports/IncomeStatement';
import BalanceSheet from '../reports/BalanceSheet';
import Statement from '../reports/Statement';
import CustomerStatement from '../reports/CustomerStatement';
import CollectionReport from '../reports/CollectionReport';
import OwnerStatement from "../reports/OwnerStatement";
import NewOwnerStatement from "../reports/NewOwnerStatement";

import AccountStatement from '../reports/AccountStatement';
import EndMonthReport from '../reports/EndMonthReport';



// RENTALS
import Properties from '../features/rentals/Properties';
import PropertyNew from '../features/rentals/PropertyNew';
import Property from '../features/rentals/Property';
import Leases from '../features/rentals/Leases';
import LeaseNew from '../features/rentals/LeaseNew';
import LeaseEdit from '../features/rentals/LeaseEdit';
import Owners from '../features/crm/Owners';
import Owner from '../features/crm/Owner';
import Meters from '../features/rentals/Meters';
import Meter from '../features/rentals/Meter';
import UploadPropertyData from "../features/rentals/UploadPropertyData";
import BulkCreateMeters from "../features/rentals/BulkCreateMeters";



// SETTINGS
import AppSettings from '../features/settings/AppSettings';
import PaymentModes from '../features/settings/PaymentModes';
import PaymentModeNew from '../features/settings/PaymentModeNew';
import ClientSettings from '../features/settings/ClientSettings';
import ProtectedRoute from "./ProtectedRoute";
import UploadClientData from "../features/settings/UploadClientData";
import ManagementContracts from "../features/rentals/ManagementContracts";

// import AdminRoutes from './components/AdminRoutes';


const ClientRoutes = ({ userType }) => {
 
  return (
    <Routes>
        <Route path="" element={ <Dashboard/> } /> 
        <Route path="stats" element={ <Dashboard/> } />  #Sunset this link
        <Route path="business-intelligence" element={ <Dashboard/> } /> 

        <Route path="contactnew" element={ <ContactNew/> } />  #Sunset this link
        <Route path="contact-new" element={ <ContactNew/> } /> 
        <Route path="leads" element={ <Leads/> } />  
        <Route path="lead/:id" element={ <Lead/> } />  
        <Route path="leadnew" element={ <LeadNew/> } />  
        <Route path="customers" element={ <Customers/> } />  
        <Route path="customer/:id" element={ <Customer/> } />  
        <Route path="customernew" element={ <CustomerNew/> } />  
        <Route path="customeredit/:id" element={ <CustomerEdit/> } /> 
        <Route path="owners" element={ <Owners/> } />  
        <Route path="owner/:id" element={ <Owner/> } />  

        <Route path="suppliers" element={ <Suppliers/> } />  
        <Route path="suppliernew" element={ <SupplierNew/> } />  
        <Route path="supplier/:id" element={ <Supplier/> } />  

        <Route path="products" element={ <Products/> } />  
        <Route path="product/:id" element={ <Product/> } />  
        <Route path="price-lists" element={ <PriceLists/> } />
        <Route path="price-list/:id" element={ <PriceList/> } />  
        <Route path="price-list-item/:id" element={ <PriceListItem/> } />  
        <Route path="product-new" element={ <ProductNew/> } /> 


        <Route path="properties" element={ <Properties/> } />  
        <Route path="add-property" element={ <PropertyNew/> } />  
        <Route path="property/:id" element={ <Property/> } />  
        <Route path="management-contracts" element={ <ManagementContracts/> } />
        <Route path="leases" element={ <Leases/> } />  
        <Route path="lease-new" element={ <LeaseNew/> } />  
        <Route path="lease-edit" element={ <LeaseEdit/> } />  
        <Route path="upload-property-data" element={ <UploadPropertyData/> } />  
        <Route path="bulk-create-meters" element={ <BulkCreateMeters/> } />  

        <Route path="meters" element={ <Meters/> } />  
        <Route path="meter/:id" element={ <Meter/> } />  


        <Route path="employees" element={ <Employees/> } />  
        <Route path="employee/:id" element={ <Employee/> } />  
        <Route path="employeenew" element={ <EmployeeNew/> } />  
        <Route path="expense-types" element={ <ExpenseTypes/> } />  
        <Route path="expense-type-new" element={ <ExpenseTypeNew/> } />  
        <Route path="expense-reports" element={ <ExpenseReports/> } />  
        <Route path="expense-report-new" element={ <ExpenseReportNew/> } />  

        <Route path="accounts" element={ <Accounts/> } />  
        <Route path="account/:id" element={ <Account/> } />  
        <Route path="accountnew" element={ <AccountNew/> } />  
        <Route path="payments" element={ <Payments/> } />  
        <Route path="payment/:id" element={ <Payment/> } />  

        <Route path="transactions" element={ <Transactions/> } />  {/* To be refactored with General Ledger */}
        <Route path="general-ledger" element={ <Transactions/> } />  

        <Route path="transaction/:id" element={ <Transaction/> } />  
        <Route path="transactionnew" element={ <TransactionNew/> } />  
        <Route path="confirmed" element={ <Confirmed/> } />  
        <Route path="monthlyreport" element={ <MonthlyReport/> } />  
        <Route path="invoicereport" element={ <InvoiceReport/> } />  
        <Route path="customer-statement" element={ <CustomerStatement/> } />  {/*Re factored to "statement"*/}
        <Route path="statement" element={ <Statement/> } />  


        <Route path="income-statement" element={ <IncomeStatement/> } />  
        <Route path="balance-sheet" element={ <BalanceSheet/> } />  
        <Route path="collection-report" element={ <CollectionReport/> } />  
        <Route path="owner-statement" element={<OwnerStatement/>} />
        <Route path="new-owner-statement" element={<NewOwnerStatement/>} />

        <Route path="account-statement" element={<AccountStatement/>} />
        <Route path="end-month-report" element={<EndMonthReport/>} />

        <Route path="settings" element={ <AppSettings /> } /> 
        <Route path="payment-modes" element={ <PaymentModes/> } />  
        <Route path="payment-mode-new" element={ <PaymentModeNew/> } />  

        <Route path="client-settings" element={ <ClientSettings /> } /> 
        <Route path="upload-client-data" element={ <UploadClientData/> } />  


    </Routes>    
    );
}

export default ClientRoutes;