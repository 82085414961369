
import { useState } from "react";
import { useLocation } from 'react-router-dom';
import { useQuery } from "@tanstack/react-query";

import { IconButton, Button } from "@mui/material";
import TuneIcon from '@mui/icons-material/Tune';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel} from '@fortawesome/free-solid-svg-icons';


import { Dropdown, Menu, Space, DatePicker, Radio } from 'antd';


// import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

import authAxios from "../components/authAxios";

import moment from 'moment';

import DashboardHeader from "../components/DashboardHeader";
import './Report.css'
import Loading from "../components/Loading";


const NewOwnerStatement = () => {
    const location = useLocation();
    const property = location.state.property;
    const [reportMonth, setReportMonth] = useState(moment().format('MM'))
    const [reportYear, setReportYear] = useState(moment().format('YYYY'))
    const [filterDate, setFilterDate] = useState(null)
    const [isMonthPicker, setIsMonthPicker] = useState(true); // State to track picker type
    const [showSchedules, setShowSchedules] = useState(false);

    const { data: ownerStatement, isLoading } = useQuery({
        queryKey: ['owner-statement', reportMonth, reportYear, filterDate],
        queryFn: async () => {
            const response = await authAxios.get(`/rentals/new-owner-statement/?property=${property.id}&month=${reportMonth}&year=${reportYear}&filter_date=${filterDate}`);
            // console.log("OWNER STATEMENT", response.data)
            const data = response.data
            setShowSchedules(
                data.schedules.payments.length > 0 || 
                data.schedules.collections.length > 0 || 
                data.schedules.expenses.length > 0
            );
            return data
            // return response.data
        },
    });

    if (isLoading) return <Loading loading={true} />;


    // console.log("BAL",totalBalanceBf)
    const downloadXLS = () => {

        const worksheetData = ownerStatement.report_data.map(data => ({
            'House No': data.house_no,
            'Tenant': data.customer,
            'Contact': data.contact,
            'Balance B/F': parseFloat(data.balance_brought_forward),
            'Amount Payable': parseFloat(data.amount_payable),
            'Amount Paid': parseFloat(data.amount_paid),
            'Balance': parseFloat(data.balance),
        })); // Add totals row at the end
    // })).concat(totalsRow); // Add totals row at the end

       
        const totalsRow = {
            'House No': 'TOTALS', // Adjust label if needed
            'Tenant': '', 
            'Contact': '',
            // 'Balance B/F': totalBalanceBf,
            // 'Amount Payable': totalAmountPayable,
            // 'Amount Paid': totalAmountPaid,
            // 'Balance': totalBalance,
          };

        // Add totals row to the worksheet data
        worksheetData.push(totalsRow);
        

        // Convert data to worksheet
        const ws = XLSX.utils.json_to_sheet(worksheetData);
       

        // console.log("WS", ws)
        // console.log("TOTALS", wsTotals)


        // Append totals to the worksheet
        // XLSX.utils.sheet_add_json(ws, [wsTotals[0]], { skipHeader: true, origin: -1 });

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Collection Report');

        XLSX.writeFile(wb, `Collection_Report_${property.name}_${reportMonth}_${reportYear}.xlsx`);
    };

    const disabledDate = (current) => {
        const startOfMonth = moment().startOf('month');
        return current && (current < startOfMonth || current > moment());
    };

    const onChange = (date, dateString) => {
        setReportMonth(date.month()+1)
        setReportYear(date.year())
        if (!isMonthPicker) {
            setFilterDate(dateString);
        }
        else {
            setFilterDate(null);
        }
      };

      

    const menu = (
        // <Menu>
        //     <form className="filter-form">
        //         <Space direction="vertical">
        //             <DatePicker onChange={onChange} picker="month" placement="bottomRight" />
        //             <DatePicker onChange={onChange} picker="date" placement="bottomRight" />

        //         </Space>
        //     </form>
        // </Menu>
        <Menu>
            <form className="filter-form">
                <Space direction="vertical">
                <Radio.Group style={{ width:'100%', padding:'10px'}} onChange={(e) => setIsMonthPicker(e.target.value === 'month')} defaultValue="month">
                    <Radio.Button value="month" style={{ width: '50%' }}>Month</Radio.Button>
                    <Radio.Button value="date" style={{ width: '50%' }}>Date</Radio.Button>
                </Radio.Group>
                    {isMonthPicker ? (
                        <DatePicker style={{margin:'10px'}}  onChange={onChange} picker="month" placement="bottomRight" />
                    ) : (
                        <DatePicker 
                            style={{margin:'10px'}}  
                            onChange={onChange} 
                            picker="date" 
                            placement="bottomRight" 
                            disabledDate={disabledDate} 
                        />
                    )}
                </Space>
            </form>
        </Menu>

      );

    const reportPreTitle=property.name+" - PERIOD "+reportMonth+"/"+reportYear


    // a helper function to check if a column has data
    const hasColumnData = (columnName) => {
        return ownerStatement.report_data.some(row => row[columnName] !== 0 && row[columnName] !== null && row[columnName] !== undefined);
      };

    // const formatNumber = (number) => {
    //     return number.toLocaleString('en-UK', {
    //       minimumFractionDigits: 2,
    //       maximumFractionDigits: 2,
    //     });
    //   }

    
    const formatNumber = (number) => {
        if (number === undefined || number === null) return '0.00';
        return number.toLocaleString('en-UK', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    }

    return (
        <div className="dashboard-main owners-statement">
            <section className="dashboard-content">
                <div className="dashboard-content-header-nav">
                    <DashboardHeader dashboardTitle="Owner Statement" dashboardPreTitle={reportPreTitle} />
                    <div className="dashboard-content-header-nav-icons">
                        {/* <button onClick={downloadXLS}>Download XLS</button> */}
                        <IconButton onClick={downloadXLS}>
                            <FontAwesomeIcon className="menu-icon" icon={faFileExcel} />
                        </IconButton>
                        <Dropdown overlay={menu} trigger={['click']} placement="bottomRight" arrow>
                      
                        <Button className="menu-button-filter" variant="outlined" startIcon={<TuneIcon />}>
                            Filter
                        </Button>

                    </Dropdown>
                    </div>
                
                </div>

                <table >
                        <thead>
                        <tr>
                            <th>House No</th>
                            <th>Tenant</th>
                            <th>Contact</th>
                            <th className="cell-align-right">Balance B/F</th>
                            <th className="cell-align-right">Deposit</th>
                            <th className="cell-align-right">Rent</th>
                            {hasColumnData('service_payable') && <th className="cell-align-right">S/Charge</th>}
                            {hasColumnData('garbage_payable') && <th className="cell-align-right">Garbage</th>}
                            {hasColumnData('security_payable') && <th className="cell-align-right">Security</th>}
                            {hasColumnData('water_payable') && <th className="cell-align-right">Water</th>}
                            {hasColumnData('electricity_payable') && <th className="cell-align-right">Electricity</th>}
                            {hasColumnData('adjustments') && <th className="cell-align-right">Adjustments</th>}
                            <th className="cell-align-right">Total Due</th>
                            <th className="cell-align-right">Paid</th>
                            <th className="cell-align-right">Balance</th>
                        </tr>
                        </thead>

                          
                        <tbody >
                            {ownerStatement && ownerStatement.report_data.map(data => (
                            <tr key={data.house_no}>
                                <td>{data.house_no}</td>
                                <td>{data.customer}</td>
                                <td>{data.contact}</td>
                                <td className="cell-align-right">{Number(data.tenant_balance_bf).toLocaleString()}</td>
                                <td className="cell-align-right">{Number(data.deposit_payable).toLocaleString()}</td>
                                <td className="cell-align-right">{Number(data.rent_payable).toLocaleString()}</td>
                                {hasColumnData('service_payable') && <td className="cell-align-right">{Number(data.service_payable).toLocaleString()}</td>}
                                {hasColumnData('garbage_payable') && <td className="cell-align-right">{Number(data.garbage_payable).toLocaleString()}</td>}
                                {hasColumnData('security_payable') && <td className="cell-align-right">{Number(data.security_payable).toLocaleString()}</td>}
                                {hasColumnData('water_payable') && <td className="cell-align-right">{Number(data.water_payable).toLocaleString()}</td>}
                                {hasColumnData('electricity_payable') && <td className="cell-align-right">{Number(data.electricity_payable).toLocaleString()}</td>}
                                {hasColumnData('adjustments') && <td className="cell-align-right">{Number(data.adjustments).toLocaleString()}</td>}
                                <td className="cell-align-right">{Number(data.amount_payable).toLocaleString()}</td>

                                <td className="cell-align-right">{Number(data.amount_paid).toLocaleString()}</td>
                                <td className="cell-align-right">{Number(data.balance).toLocaleString()}</td>
                            </tr>

                            ))}

                            {ownerStatement && ownerStatement.totals &&
                            <>
                            <tr className="totals-row" >
                                <th className="cell-bold" colSpan="3">TOTALS</th>
                                {/* <th className="cell-align-right">{ownerStatement.totals.balance_brought_forward.toLocaleString()}</th> */}
                                <th className="cell-align-right">{ownerStatement.totals.tenant_balance_bf.toLocaleString()}</th>
                                <th className="cell-align-right">{ownerStatement.totals.deposit_payable.toLocaleString()}</th>
                                <th className="cell-align-right">{ownerStatement.totals.rent_payable.toLocaleString()}</th>
                                {hasColumnData('service_payable') && <th className="cell-align-right">{ownerStatement.totals.service_payable.toLocaleString()}</th>}
                                {hasColumnData('garbage_payable') && <th className="cell-align-right">{ownerStatement.totals.garbage_payable.toLocaleString()}</th>}
                                {hasColumnData('security_payable') && <th className="cell-align-right">{ownerStatement.totals.security_payable.toLocaleString()}</th>}
                                {hasColumnData('water_payable') && <th className="cell-align-right">{ownerStatement.totals.water_payable.toLocaleString()}</th>}
                                {hasColumnData('electricity_payable') && <th className="cell-align-right">{ownerStatement.totals.electricity_payable.toLocaleString()}</th>}
                                {hasColumnData('adjustments') && <th className="cell-align-right">{ownerStatement.totals.adjustments.toLocaleString()}</th>}
                                <th className="cell-align-right">{ownerStatement.totals.amount_payable.toLocaleString()}</th>
                                <th className="cell-align-right">{ownerStatement.totals.amount_paid.toLocaleString()}</th>
                                <th className="cell-align-right">{ownerStatement.totals.balance.toLocaleString()}</th>
                            </tr>
                           

                            <tr>
                                <td colSpan="4">MANAGEMENT FEES  {(ownerStatement.management_fee_rate*100).toFixed(2)}%</td>
                                <td className="cell-align-right"></td>
                                <th className="cell-align-right">{formatNumber(ownerStatement.totals.management_fee)}</th>
                                <td className="cell-align-right"></td>
                                <td className="cell-align-right"></td>
                            </tr>

                            <tr>
                                <td colSpan="4">PAYMENTS</td>
                                <td className="cell-align-right"></td>
                                <th className="cell-align-right">{formatNumber(ownerStatement.totals.payments)}</th>
                                <td className="cell-align-right"></td>
                                <td className="cell-align-right"></td>
                            </tr>

                            <tr>
                                <td colSpan="4">COLLECTIONS</td>
                                <td className="cell-align-right"></td>
                                <th className="cell-align-right">{formatNumber(ownerStatement.totals.collections)}</th>
                                <td className="cell-align-right"></td>
                                <td className="cell-align-right"></td>
                            </tr>

                            <tr>
                                <td colSpan="4">EXPENSES</td>
                                <td className="cell-align-right"></td>
                                <th className="cell-align-right">{formatNumber(ownerStatement.totals.property_expenses)}</th>
                                <td className="cell-align-right"></td>
                                <td className="cell-align-right"></td>
                            </tr>

                            <tr>
                                <td colSpan="4">TOTAL DEDUCTIONS</td>
                                <td className="cell-align-right"></td>
                                <th className="cell-align-right">{formatNumber(ownerStatement.totals.deductions)}</th>
                                <td className="cell-align-right"></td>
                                <td className="cell-align-right"></td>
                            </tr>

                            <tr>
                                <td colSpan="4">DEPOSITS</td>
                                <td className="cell-align-right"></td>
                                <th className="cell-align-right">{formatNumber(ownerStatement.totals.deposit_payable)}</th>
                                <td className="cell-align-right"></td>
                                <td className="cell-align-right"></td>
                            </tr>

                            {hasColumnData('service_payable') &&
                            <tr>
                                <td colSpan="4">SERVICE CHARGE</td>
                                <td className="cell-align-right"></td>
                                <th className="cell-align-right">{formatNumber(ownerStatement.totals.service_payable)}</th>
                                <td className="cell-align-right"></td>
                                <td className="cell-align-right"></td>
                            </tr>
                            }

                            {hasColumnData('security_payable') &&
                            <tr>
                                <td colSpan="4">SECURITY</td>
                                <td className="cell-align-right"></td> 
                                <th className="cell-align-right">{formatNumber(ownerStatement.totals.security_payable)}</th>
                                <td className="cell-align-right"></td>
                                <td className="cell-align-right"></td>
                            </tr>
                            }

                            {hasColumnData('garbage_payable') &&
                            <tr>
                                <td colSpan="4">GARBAGE</td>
                                <td className="cell-align-right"></td>
                                <th className="cell-align-right">{formatNumber(ownerStatement.totals.garbage_payable)}</th>
                                <td className="cell-align-right"></td>
                                <td className="cell-align-right"></td>
                            </tr>
                            }

                            {hasColumnData('water_payable') &&
                            <tr>
                                <td colSpan="4">WATER</td>
                                <td className="cell-align-right"></td>
                                <th className="cell-align-right">{formatNumber(ownerStatement.totals.water_payable)}</th>
                                <td className="cell-align-right"></td>
                                <td className="cell-align-right"></td>
                            </tr>
                            }      

                            {hasColumnData('electricity_payable') &&
                            <tr>
                                <td colSpan="4">ELECTRICITY</td>
                                <td className="cell-align-right"></td>
                                <th className="cell-align-right">{formatNumber(ownerStatement.totals.electricity_payable)}</th>
                                <td className="cell-align-right"></td>
                                <td className="cell-align-right"></td>
                            </tr>
                            }

                            {/* {ownerStatement.totals.inactive_tenant_txns !==0 &&
                            <tr>
                                <td colSpan="4">INACTIVE TENANT TRANSACTIONS</td>
                                <td className="cell-align-right"></td>
                                <th className="cell-align-right">{formatNumber(ownerStatement.totals.inactive_tenant_txns)}</th>
                                <td className="cell-align-right"></td>
                                <td className="cell-align-right"></td>
                            </tr>
                            } */}

                            {ownerStatement.totals.owner_adjustments !==0 &&
                            <tr>
                                <td colSpan="4">BALANCE ADJUSTMENT (THIS MONTH)</td>
                                <td className="cell-align-right"></td>
                                <th className="cell-align-right">{formatNumber(ownerStatement.totals.owner_adjustments)}</th>
                                <td className="cell-align-right"></td>
                                <td className="cell-align-right"></td>
                            </tr>
                            }

                            <tr>
                                <td colSpan="4">BALANCE B/F</td>
                                <td className="cell-align-right"></td>
                                <th className="cell-align-right">{formatNumber(ownerStatement.totals.owner_balance_bf)}</th>
                                <td className="cell-align-right"></td>
                                <td className="cell-align-right"></td>
                            </tr>
                            </>
                            }
                            
                        </tbody>

                        <tfoot>
                        {ownerStatement && ownerStatement.totals &&

                          <tr>
                              <th colSpan="4">AMOUNT DUE</th>
                              <th className="cell-align-right"></th>
                              <th className="cell-align-right">{formatNumber(ownerStatement.totals.owner_balance)}</th>
                              <th className="cell-align-right"></th>
                              <th className="cell-align-right"></th>
                          </tr>
                        }

                        </tfoot>

                </table>



                {/* Add this new section after the main table */}
                {ownerStatement && ownerStatement.totals && showSchedules && (
                    // (ownerStatement.schedules?.payments?.length || ownerStatement.schedules?.collections?.length || ownerStatement.schedules?.expenses?.length )&& (
                    <section className="deductions-schedule">
                        <h3>Schedule of Deductions</h3>
                        
                        {/* Payments Section */}
                        {ownerStatement.schedules?.payments?.length > 0 && (
                            <div className="schedule-section">
                                {/* <h4>Payments</h4> */}
                                <div className="subtitle">Payments</div>

                                <table>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Reference</th>
                                            <th>Description</th>
                                            <th className="cell-align-right">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ownerStatement.schedules.payments.map((item, index) => (
                                            <tr key={`payment-${index}`}>
                                                <td>{moment(item.date).format('DD/MM/YYYY')}</td>
                                                <td>{item.reference}</td>
                                                <td>{item.description}</td>
                                                <td className="cell-align-right">{formatNumber(item.amount)}</td>
                                            </tr>
                                        ))}
                                        <tr className="subtotal-row">
                                            <td colSpan="3">Total Payments</td>
                                            <td className="cell-align-right">{formatNumber(ownerStatement.totals.payments)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}

                        {/* Collections Section */}
                        {ownerStatement.schedules?.collections?.length > 0 && (
                            <div className="schedule-section">
                                {/* <h4>Collections</h4> */}
                                <div className="subtitle">Collections</div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Reference</th>
                                            <th>Description</th>
                                            <th className="cell-align-right">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ownerStatement.schedules.collections.map((item, index) => (
                                            <tr key={`collection-${index}`}>
                                                <td>{moment(item.date).format('DD/MM/YYYY')}</td>
                                                <td>{item.reference}</td>
                                                <td>{item.description}</td>
                                                <td className="cell-align-right">{formatNumber(item.amount)}</td>
                                            </tr>
                                        ))}
                                        <tr className="subtotal-row">
                                            <td colSpan="3">Total Collections</td>
                                            <td className="cell-align-right">{formatNumber(ownerStatement.totals.collections)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}


                        {/* Property Expenses Section */}
                        {ownerStatement.schedules?.expenses?.length > 0 && (
                            <div className="schedule-section">
                                {/* <h4>Property Expenses</h4> */}
                                <div className="subtitle">Property Expenses</div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Reference</th>
                                            <th>Description</th>
                                            <th className="cell-align-right">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ownerStatement.schedules.expenses.map((item, index) => (
                                            <tr key={`expense-${index}`}>
                                                <td>{moment(item.date).format('DD/MM/YYYY')}</td>
                                                <td>{item.reference}</td>
                                                <td>{item.description}</td>
                                                <td className="cell-align-right">{formatNumber(item.amount)}</td>
                                            </tr>
                                        ))}
                                        <tr className="subtotal-row">
                                            <td colSpan="3">Total Expenses</td>
                                            <td className="cell-align-right">{formatNumber(ownerStatement.totals.property_expenses)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}

                    </section>
                )}

            </section>
        </div>
    );
};

export default NewOwnerStatement;


