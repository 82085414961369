import { useState, memo } from "react";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";

import useAxiosInterceptor from "./useAxiosInterceptor";

import authAxios from './authAxios'

import { List } from '@mui/material';
import { ListItemButton } from '@mui/material';
import { ListItemText } from '@mui/material';
import { Collapse } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import PortraitSharpIcon from '@mui/icons-material/PortraitSharp';
import AppsSharpIcon from '@mui/icons-material/AppsSharp';
import GroupIcon from '@mui/icons-material/Group';
import InventorySharpIcon from '@mui/icons-material/InventorySharp';
import ShoppingCartSharpIcon from '@mui/icons-material/ShoppingCartSharp';
import QrCodeSharpIcon from '@mui/icons-material/QrCodeSharp';
import ShoppingBagSharpIcon from '@mui/icons-material/ShoppingBagSharp';
import BarChartIcon from '@mui/icons-material/BarChart';
import SettingsSharpIcon from '@mui/icons-material/SettingsSharp';
import SpokeIcon from '@mui/icons-material/Spoke';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import SellIcon from '@mui/icons-material/Sell';
import HomeWorkSharpIcon from '@mui/icons-material/HomeWorkSharp';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';

import DescriptionIcon from '@mui/icons-material/Description';

import { faBarcode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouseCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { faFileContract } from "@fortawesome/free-solid-svg-icons";


import logo from '../assets/koddi-logo.svg'


const Sidebar = () => {
    const [open, setOpen] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState([]);
    const {error} = useAxiosInterceptor();


    // const fetchMenu = async () => {
    //     const response = await authAxios.get("/saas/client-menu")
    //     return response.data
    // }
    // const {data:menuItems, status} = useQuery(['menuItems'], fetchMenu)

    const { data: clientModules } = useQuery({
        queryKey: ['clientModules'],
        queryFn: async () => {
            const response = await authAxios.get('/saas/client-modules');
            const cm = response.data.find((cm) => cm.client === parseInt(localStorage.getItem('clientID')))
            return cm.modules
        },
    });

    // const { data: clientProfile } = useQuery({
    //     queryKey: ['clientProfile'],
    //     queryFn: async () => {
    //       const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`);
    //       return response.data
    //     },
    // });


    // console.log("MODULES", clientModules)


    const MenuIcon = ({item}) =>{
        if (item.name === "Dashboard"){
            return <AppsSharpIcon className="dashboard-navicon"/>
        }
        // if (item.name === "POS"){
        if (item.code === "POS"){
            // return <PointOfSaleIcon className="dashboard-navicon"/>
            return <ShoppingCartSharpIcon className="dashboard-navicon"/>

        }
        if (item.name === "Sales"){
            return <SellIcon className="dashboard-navicon"/>
        }
        // if (item.name === "CRM"){
        if (item.name === "CRM" || item.name === "Residents" || item.name === "Tenants"){
            return <GroupIcon className="dashboard-navicon"/>
        }
        if (item.name === "Products"){
            return <InventorySharpIcon className="dashboard-navicon"/>
        }
        if (item.name === "Purchase"){
            return <LocalShippingIcon className="dashboard-navicon"/>
        }
        if (item.name === "Inventory"){
            return <FontAwesomeIcon icon={faBarcode} className="dashboard-navicon"/>
            // return <QrCodeSharpIcon className="dashboard-navicon"/>
        }
        if (item.name === "Orders"){
            return <ShoppingCartSharpIcon className="dashboard-navicon"/>
        }
        // if (item.name === "Human Resources"){
        if (item.name === "Human Resources" || item.name === "Owners"){
            return <PortraitSharpIcon className="dashboard-navicon"/>
        }
        // if (item.name === "Integrations"){
        //     return <SpokeIcon className="dashboard-navicon"/>
        // }
        if (item.code === "ECOM" || item.name === "E-Comm Platforms"){
            return <SpokeIcon className="dashboard-navicon"/>
        }
        // if (item.name === "Rentals"){

        if (item.name === "Rentals" ||item.name === "Properties" ){
            return <HomeWorkSharpIcon className="dashboard-navicon"/>
            // return <FontAwesomeIcon icon={faHouseCircleCheck} className="dashboard-navicon"/>

        }

        if (item.name === "Utilities"){
            return <NetworkCheckIcon className="dashboard-navicon"/>
        }


        if (item.name === "Management Contracts" ){
            return <DescriptionIcon className="dashboard-navicon"/>
            // return <FontAwesomeIcon icon={faFileContract} className="dashboard-navicon"/>

        }

        if (item.name === "Subscriptions" || item.name === "Leases" ){
            return <EventRepeatIcon className="dashboard-navicon"/>
        }
        if (item.name === "Accounting"){
            return <BarChartIcon className="dashboard-navicon"/>
        }
        if (item.name === "Settings"){
            return <SettingsSharpIcon className="dashboard-navicon"/>
        }
    }

    const handleClick = (index) => {
        // const newOpen = [...open];
        const newOpen = {...open}; 
        newOpen[index] = !newOpen[index];
        setOpen(newOpen);
        setSelectedIndex(index);
        // console.log("IDX",index)
        // console.log("OPEN",newOpen)

    };


    // const SingleLevel = ({item}) => {
    //     return(
    //         <ListItemButton 
    //             // key={item.id}
    //             selected={selectedIndex === item.id}
    //             onClick={() => handleClick(item.id)}
    //             className="dashboard-navlink" 
    //             component={Link} to={item.path}
    //         >
    //             <MenuIcon item={item} />
    //             <ListItemText primary={item.name} className="dashboard-navlink-text"/>
    //         </ListItemButton>
    //     )
    // }

    // const MultiLevel = ({item}) => {
    //     return(
    //         <>
    //         <ListItemButton 
    //             selected={selectedIndex === item.id}
    //             className="dashboard-navlink" 
    //             onClick={() => handleClick(item.id)}>
    //             <MenuIcon item={item} 
    //         />
    //             <ListItemText primary={item.name} className="dashboard-navlink-text"/>
    //             {open[item.id] ? <ExpandLess /> : <ExpandMore />}
    //         </ListItemButton>
    //         <Collapse in={open[item.id]} timeout="auto" unmountOnExit>
    //             <List component="div" disablePadding>
    //                 {menuItems.map((item1) => ( 
    //                     <div key={item1.id}>
    //                         {item1.parent_menu===item.menu? 
    //                         <>
    //                             {item1.has_submenu? 
    //                                 <>
    //                                 <ListItemButton  selected={selectedIndex === item1.id} className="dashboard-submenu-navlink" onClick={() => handleClick(item1.id)}>
    //                                     <ListItemText primary={item1.name} className="dashboard-navlink-text"/>
    //                                     {open[item1.id] ? <ExpandLess /> : <ExpandMore />}
    //                                 </ListItemButton>

    //                                 <Collapse in={open[item1.id]} timeout="auto" unmountOnExit>
    //                                     <List component="div" disablePadding>
    //                                     {menuItems.map(item2 => (
    //                                         <div key={item2.id}>
    //                                             {item2.parent_menu===item1.menu?
    //                                                 <ListItemButton 
    //                                                     selected={selectedIndex === item2.id}
    //                                                     className="dashboard-submenu-navlink" 
    //                                                     onClick={() => handleClick(item2.id)}
    //                                                     component={Link} to={item2.path}
    //                                                 >
    //                                                     <ListItemText primary={item2.name} className="dashboard-submenu-text"/>
    //                                                 </ListItemButton>    
    //                                                 : 
    //                                                 null   
    //                                             }
    //                                         </div>
                                                               
    //                                     ))}
    //                                     </List>
    //                                 </Collapse>
    //                                 </>
    //                             :
    //                             <ListItemButton 
    //                                 key={item1.id} 
    //                                 selected={selectedIndex === item1.id}
    //                                 className="dashboard-submenu-navlink" 
    //                                 component={Link} to={item1.path}
    //                                 onClick={() => handleClick(item1.id)}
    //                                 >
    //                                 <ListItemText primary={item1.name} className="dashboard-navlink-text"/>
    //                             </ListItemButton>
    //                             }
    //                         </>
    //                         :
    //                         null}
                        
    //                     </div>

    //                 ))} 
    //             </List>
    //         </Collapse>
    //     </>

    //     )

    // }

    
    return(


    // <section className="dashboard-menu flex-column">
    //     <div className="dashboard-brand">
    //         <Link to="/">
    //             <img src={logo} alt="brand logo"/>  
    //         </Link>

    //     </div>

    //     <List >
    //         {menuItems && menuItems.map(item => (
    //             item.level===1? 
    //                 item.has_submenu?
    //                 <MultiLevel item={item} key={item.id} />
    //                 :
    //                 <SingleLevel item={item} key={item.id} />
    //             : 
    //             null
    //         ))}

    //     </List>


    // </section>
        <section className="dashboard-menu flex-column">
            <div className="dashboard-brand">
                <Link to="/">
                    <img src={logo} alt="brand logo"/>  
                </Link>

            </div>
            <List >
                {/* {clientProfile && clientProfile.menu_profile.menu_items.map(item => ( */}
                {/* {clientProfile && clientProfile.modules.map(item => (  */}
                {clientModules && clientModules.map(item => ( 


                    // <div key={module.id}>
                    // <div key={item.id}>


                    // {/* {item.parent === null && */}
                    // {module.menu_items.map(item=>(

                    // {item.parent === null && 

                    // <div key={item.id}> 

                    <div key={item.id}>
                        
                        {/* {item.parent === null && 
                        <> */}







                        {/* IF PARENT MENU HAS SUBMENUS */}
                        {/* {console.log("ITEM",item.name, item.menu_items.length)} */}

                        {/* {item.children.length>0 ? */}
                        {item.menu_items.length>0 ?

                        <>
                            <ListItemButton 
                                selected={selectedIndex === item.id}
                                className="dashboard-navlink" 
                                onClick={() => handleClick(item.id)}
                                // component={Link} to={item.path}
                            >
                                <MenuIcon item={item} />
                                <ListItemText primary={item.name} className="dashboard-navlink-text"/>
                                {/* {item.children.length>0 && (open[item.id] ? <ExpandLess /> : <ExpandMore />)} */}
                                {(open[item.id] ? <ExpandLess /> : <ExpandMore />)}

                            </ListItemButton>
                            <Collapse in={open[item.id]} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                {/* {item.children.map((item1) => (  */}
                                {item.menu_items.map((item1) => ( 


                                    // <div key={item1.id}>
                                    <div key={`${item.id}-${item1.id}`}> {/*Key & Index are a combination of parent + child keys*/}


                                        {item1.children.length>0 ?
                                        <>

                                        <ListItemButton  
                                            // selected={selectedIndex === item1.id} 
                                            selected={selectedIndex === `${item.id}-${item1.id}`}
                                            className="dashboard-submenu-navlink" 
                                            // onClick={() => handleClick(item1.id)}
                                            onClick={() => handleClick(`${item.id}-${item1.id}`)}
                                        >
                                            <ListItemText primary={item1.name} className="dashboard-navlink-text"/>
                                            {/* {open[item1.id] ? <ExpandLess /> : <ExpandMore />} */}
                                            {open[`${item.id}-${item1.id}`] ? <ExpandLess /> : <ExpandMore />}
                                        </ListItemButton>
                                        {/* <Collapse in={open[item1.id]} timeout="auto" unmountOnExit> */}
                                        <Collapse in={open[`${item.id}-${item1.id}`]} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>

                                            {item1.children.map((item2) => ( 
                                                // <div key={item2.id}>
                                                <div key={`${item.id}-${item2.id}`}> {/*Key & Index are a combination of parent/module + grand child keys*/}

                                                    <ListItemButton  
                                                        // selected={selectedIndex === item2.id} 
                                                        selected={selectedIndex === `${item.id}-${item2.id}`}
                                                        className="dashboard-submenu-navlink" 
                                                        // onClick={() => handleClick(item2.id)}
                                                        onClick={() => handleClick(`${item.id}-${item2.id}`)}
                                                        component={Link} to={item2.path}
                                                    >
                                                        <ListItemText primary={item2.name} className="dashboard-submenu-text"/>
                                                    </ListItemButton>
                                                </div>
                                            ))}

                                            </List>
                                        </Collapse>

                                        </>
                                        :
                                        <ListItemButton  
                                            // selected={selectedIndex === item1.id} 
                                            selected={selectedIndex === `${item.id}-${item1.id}`}
                                            className="dashboard-submenu-navlink" 
                                            // onClick={() => handleClick(item1.id)}
                                            onClick={() => handleClick(`${item.id}-${item1.id}`)}
                                            component={Link} to={item1.path}
                                        >
                                            <ListItemText primary={item1.name} className="dashboard-navlink-text"/>
                                        </ListItemButton>
                                        }

                                    </div>
                                ))}

                                </List>
                            </Collapse> 
                        </>
                        :
                        <ListItemButton 
                            selected={selectedIndex === item.id}
                            className="dashboard-navlink" 
                            onClick={() => handleClick(item.id)}
                            component={Link} to={item?.path || '#'} //Optional Chaining with fallback menu
                        >
                            <MenuIcon item={item} />
                            <ListItemText primary={item.name} className="dashboard-navlink-text"/>
                        </ListItemButton>
                        }
                    </div>
                    ))
                    }


                    {/* </div> */}

                {/* ))} */}
            </List>
        </section> 
    )


}

export default memo(Sidebar);