
import { useState } from "react";
import { useLocation } from 'react-router-dom';
import authAxios from "../components/authAxios";

import { useQuery } from "@tanstack/react-query";

import { IconButton, Button } from "@mui/material";
import TuneIcon from '@mui/icons-material/Tune';


// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel} from '@fortawesome/free-solid-svg-icons';

// Ant Design
import { Dropdown, Menu, Space, DatePicker } from 'antd';


// import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';


import moment from 'moment';


import Loading from "../components/Loading";

// import Layout from "../components/Layout";
import DashboardHeader from "../components/DashboardHeader";
import './Report.css'
// import { width } from "@fortawesome/free-brands-svg-icons/fa42Group";

const CollectionReport = () => {
    const location = useLocation();
    const property = location.state.property;
    const [reportMonth, setReportMonth] = useState(moment().format('MM'))
    const [reportYear, setReportYear] = useState(moment().format('YYYY'))
    // const [totalBalanceBf, setTotalBalanceBf] = useState(0)


    const { data: collectionReport, isLoading } = useQuery({
        queryKey: ['collection-report', reportMonth, reportYear],
        queryFn: async () => {
            const response = await authAxios.get(`/rentals/collection-report/?property=${property.id}&month=${reportMonth}&year=${reportYear}`);
            return response.data
        },
    });

    if (isLoading) return <Loading loading={true} />;


    const totalBalanceBf = collectionReport ? collectionReport.reduce((acc, data) => {
        return acc + (parseFloat(data.balance_brought_forward) || 0);
    }, 0) : 0;

    const totalAmountPayable = collectionReport ? collectionReport.reduce((acc, data) => {
        return acc + (parseFloat(data.amount_payable) || 0);
    }, 0) : 0;

    const totalAmountPaid = collectionReport ? collectionReport.reduce((acc, data) => {
        return acc + (parseFloat(data.amount_paid) || 0);
    }, 0) : 0;

    const totalBalance = collectionReport ? collectionReport.reduce((acc, data) => {
        return acc + (parseFloat(data.balance) || 0);
    }, 0) : 0;

    // console.log("BAL",totalBalanceBf)
    const downloadXLS = () => {

        const worksheetData = collectionReport.map(data => ({
            'House No': data.house_no,
            'Tenant': data.customer,
            'Contact': data.contact,
            'Balance B/F': parseFloat(data.balance_brought_forward),
            'Amount Payable': parseFloat(data.amount_payable),
            'Amount Paid': parseFloat(data.amount_paid),
            'Balance': parseFloat(data.balance),
        })); // Add totals row at the end
    // })).concat(totalsRow); // Add totals row at the end

       
        const totalsRow = {
            'House No': 'TOTALS', // Adjust label if needed
            'Tenant': '', 
            'Contact': '',
            'Balance B/F': totalBalanceBf,
            'Amount Payable': totalAmountPayable,
            'Amount Paid': totalAmountPaid,
            'Balance': totalBalance,
          };

        // Add totals row to the worksheet data
        worksheetData.push(totalsRow);
        

        // Convert data to worksheet
        const ws = XLSX.utils.json_to_sheet(worksheetData);
       

        // console.log("WS", ws)
        // console.log("TOTALS", wsTotals)


        // Append totals to the worksheet
        // XLSX.utils.sheet_add_json(ws, [wsTotals[0]], { skipHeader: true, origin: -1 });

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Collection Report');

        XLSX.writeFile(wb, `Collection_Report_${property.name}_${reportMonth}_${reportYear}.xlsx`);
    };

    const onChange = (date, dateString) => {
        // console.log(date);
        setReportMonth(date.month()+1)
        setReportYear(date.year())
      };

    const menu = (
        <Menu>
            <form className="filter-form">
                <Space direction="vertical">
                    <DatePicker onChange={onChange} picker="month" placement="bottomRight" />
                </Space>
            </form>
        </Menu>
      );
    const reportPreTitle=property.name+" - PERIOD "+reportMonth+"/"+reportYear


    return (
        <div className="dashboard-main collection-report">
            <section className="dashboard-content">
                <div className="dashboard-content-header-nav">
                    <DashboardHeader dashboardTitle="Collection Report" dashboardPreTitle={reportPreTitle} />
                    <div className="dashboard-content-header-nav-icons">
                        {/* <button onClick={downloadXLS}>Download XLS</button> */}
                        <IconButton onClick={downloadXLS}>
                            <FontAwesomeIcon className="menu-icon" icon={faFileExcel} />
                        </IconButton>
                        <Dropdown overlay={menu} trigger={['click']} placement="bottomRight" arrow>
                      
                        <Button className="menu-button-filter" variant="outlined" startIcon={<TuneIcon />}>
                            Filter
                        </Button>

                    </Dropdown>
                    </div>
                
                </div>

                <table >
                        <thead>
                        <tr>
                            <th>House No</th>
                            <th>Tenant</th>
                            <th>Contact</th>
                            <th className="cell-align-right">Balance B/F</th>
                            <th className="cell-align-right">Amount Payable</th>
                            <th className="cell-align-right">Amount Paid</th>
                            <th className="cell-align-right">Balance</th>
                        </tr>
                        </thead>

                        {collectionReport && collectionReport.map(data => (
                        <tbody key={data.house_no}>
                            <tr>
                                <td>{data.house_no}</td>
                                <td>{data.customer}</td>
                                <td>{data.contact}</td>
                                <td className="cell-align-right">{Number(data.balance_brought_forward).toLocaleString()}</td>
                                <td className="cell-align-right">{Number(data.amount_payable).toLocaleString()}</td>
                                <td className="cell-align-right">{Number(data.amount_paid).toLocaleString()}</td>
                                <td className="cell-align-right">{Number(data.balance).toLocaleString()}</td>
                            </tr>
                        </tbody>
                        ))}

                        <tfoot>
                          <tr>
                              <th colSpan="3">TOTALS</th>
                              <th className="cell-align-right">{totalBalanceBf.toLocaleString()}</th>
                              <th className="cell-align-right">{totalAmountPayable.toLocaleString()}</th>
                              <th className="cell-align-right">{totalAmountPaid.toLocaleString()}</th>
                              <th className="cell-align-right">{totalBalance.toLocaleString()}</th>
                          </tr>
                        </tfoot>



                </table>
            </section>
        </div>
    );
};

export default CollectionReport;


