import { useState, useEffect, useRef, useContext } from "react";
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

// import useAxios from "../../components/useAxios";
import authAxios from "../../components/authAxios";

import { useNavigate, useLocation } from "react-router-dom";
import { Autocomplete } from "@mui/material";
import { TextField } from "@mui/material";

import { Modal } from 'antd';


import NumberFormat from "react-number-format";
import {UserContext} from "../../App"
import UserAlerts from "../../components/UserAlerts";
// import DashboardHeader from "../../components/DashboardHeader";

import './Rentals.css'


function PropertyEdit({property, isModalOpen, setIsModalOpen}) {

    // const {response, error, fetchData}  = useAxios();
    const [error, setError] = useState('');
    const errorRef = useRef(null);


    const queryClient = useQueryClient();

    const page_size = useContext(UserContext).page_size;

    const user = localStorage.getItem('userID');
    const client = localStorage.getItem('clientID');


    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const { data: propertyTypes } = useQuery({
        queryKey: ['propertyTypes'],
        queryFn: async () => {
          const response = await authAxios.get("/rentals/property-types");
          return response.data
        },
    });


    const { data: owners } = useQuery({
        queryKey: ['owners'],
        queryFn: async () => {
        //   const response = await authAxios.get(`/rentals/owners/?page_size=${page_size}`);
          const response = await authAxios.get("/rentals/owners");
          return response.data
        },
        keepPreviousData : true,
    });


    const [clientType, setClientType] = useState('');
    // const { data: rentalConfig } = useQuery({
    //     queryKey: ['rentalConfig'],
    //     queryFn: async () => {
    //         const response = await authAxios.get(`/rentals/rental-config`);
    //         console.log(response.data)
    //         const config = response.data.find(config => parseInt(config.client) === parseInt(client));
    //         setClientType(config.client_type)
    //         return response.data
    //     },
    // });

    const { data: rentalConfig } = useQuery({
        queryKey: ['rentalConfig', client],
        queryFn: async () => {
            const response = await authAxios.get("/rentals/rental-config/client_config");
            setClientType(response.data.client_type);
            return response.data;
        },
    });


    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [managementFee, setManagementFee] = useState('');


    const [owner, setOwner] = useState('');
    const [memo, setMemo] = useState('');
    const [nameError, setNameError] = useState(false)
    const [addressError, setAddressError] = useState(false);
    const [cityError, setCityError] = useState(false);


    const contactRef = useRef("");
    contactRef.showContactType=true    


    const mutation = useMutation({
        mutationFn: (propertyData) => {
            // return authAxios.post("/rentals/properties/", property);
            return authAxios.put(`rentals/properties/${property.id}/`, propertyData);

        },
        onError: (error, variables, context) => {
            if (error.response.data.error){
                // If it's an array, display each error message
                if (Array.isArray(error.response.data.error)) {
                    error.response.data.error.map((errorMessage) => (
                        setError(errorMessage)
                    ));
                } else {
                    // Handle single error message
                    setError(error.response.data.error); 
                }
            } else {
                // Fallback to Standard error message
                setError(error.message); 
            }      
        },
        onSuccess: (result) => {
            queryClient.invalidateQueries(['properties', 'property']);
            // navigate('/dashboard/properties/')
            queryClient.refetchQueries(['properties']);
            queryClient.refetchQueries(['property']);
            setIsModalOpen(false)
        },
    })


    const handleSubmit = (e) => {
        e.preventDefault();

        if (name === '') {
            setNameError(true)
        }

        if (address === '' ) {
            setAddressError(true)
        }

        if (city === '' ) {
            setCityError(true)
        }

        if (name && address && city ) {

            // const landlord = owner.id
            const landlord = owner?.id || null;

            const management_fee = managementFee? parseFloat(managementFee) / 100 : null;

            // const property = { type, client, name, address, city, country, email, trust_account, control_account, user, memo, landlord };
            const propertyData = {name, address, city, memo, landlord, management_fee };

            console.log(propertyData)
            mutation.mutate(propertyData)
            
        } 
    
    }

    useEffect(()=>{   
        if (property){
            setName(property.name);
            setAddress(property.address)
            setCity(property.city)
            setManagementFee(parseFloat(property.management_fee) * 100)

            if (property && property.landlord) {
                const initialLandlord = owners?.find(owner => owner.id === property.landlord);
                setOwner(initialLandlord || null);
            }
        }
    },[property, owners])

    useEffect(()=>{
        if (error){
            errorRef.current.scrollIntoView();  
        }            
    },[error])


    return(
        <>
        {isModalOpen && 
            <Modal className="dashboard-modal" title="Edit Property" visible={isModalOpen} open={isModalOpen} onOk={handleSubmit} onCancel={handleCancel}>
                <div className="dashboard-form">

                    <div ref={errorRef}>
                        <UserAlerts error={error}  />
                    </div>

                    <form noValidate autoComplete="off" onSubmit={handleSubmit}>

                        <TextField className="custom-input"
                            value={name}
                            onChange={(e) => {
                                setNameError(false)
                                setName(e.target.value)
                            }}
                            label="Name" 
                            variant="outlined" 
                            fullWidth
                            required
                            error={nameError}
                        />

                        <TextField className="custom-input"
                            value={address}
                            onChange={(e) => {
                                setAddressError(false)
                                setAddress(e.target.value)
                            }}
                            label="Physical Address" 
                            variant="outlined" 
                            fullWidth
                            required
                            error={addressError}
                        />  

                        <TextField className="custom-input"
                            value={city}
                            onChange={(e) => {
                                setCityError(false)
                                setCity(e.target.value)
                            }}
                            label="City/Town" 
                            variant="outlined" 
                            fullWidth
                            required
                            error={cityError}
                        />  

                        
                        {/* {clientType==="010" && 
                        <>
                            <Autocomplete
                                value={owner}
                                onChange={(event, newValue) => {setOwner(newValue);}}
                                options={owners || []}
                                getOptionLabel={(option) => option?.name || ''}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className="custom-input"
                                    fullWidth
                                    label="Owner"
                                />
                                )}
                            />

                            <NumberFormat 
                                value = {managementFee}
                                onChange={(e) => {
                                    setManagementFee(e.target.value)
                                }}
                                className="custom-input"
                                fullWidth
                                label="Management Fee" 
                                variant="outlined" 
                                customInput={TextField}
                                suffix="%"
                                decimalScale={2}
                                fixedDecimalScale
                                decimalSeparator="."
                                allowNegative={false}
                                isNumericString
                            />
                        </>
                        } */}

                    </form>
                </div>
            </Modal>
        }
        </>

    )

}

export default PropertyEdit;
