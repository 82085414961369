import { useState, useEffect, useRef, useContext } from "react";
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import authAxios from "../../components/authAxios";

import { useNavigate, useLocation } from "react-router-dom";
import { Autocomplete } from "@mui/material";
import { TextField } from "@mui/material";
import { Modal } from 'antd';

import NumberFormat from 'react-number-format';

import {UserContext} from "../../App"
import UserAlerts from "../../components/UserAlerts";

import './Rentals.css'


function PropertyNew({isModalOpen, setIsModalOpen}) {

    // const {response, error, fetchData}  = useAxios();
    const [error, setError] = useState('');
    const errorRef = useRef(null);
    const queryClient = useQueryClient();
    const page_size = useContext(UserContext).page_size;

    const user = localStorage.getItem('userID');
    const client = localStorage.getItem('clientID');

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const { data: propertyTypes } = useQuery({
        queryKey: ['propertyTypes'],
        queryFn: async () => {
          const response = await authAxios.get("/rentals/property-types");
          return response.data
        },
    });

    const { data: owners } = useQuery({
        queryKey: ['owners'],
        queryFn: async () => {
          const response = await authAxios.get(`/rentals/owners/?page_size=${page_size}`);
          return response.data.results
        },
        keepPreviousData : true,
    });


    const [clientType, setClientType] = useState('');
    const { data: rentalConfig } = useQuery({
        queryKey: ['rentalConfig'],
        queryFn: async () => {
            const response = await authAxios.get(`/rentals/rental-config`);
            const config = response.data.find(config => parseInt(config.client) === parseInt(client));
            setClientType(config.client_type)
            return response.data
        },
    });


    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [landlord, setLandlord] = useState('');
    const [managementFee, setManagementFee] = useState('');
    const [memo, setMemo] = useState('');

    const [nameError, setNameError] = useState(false)
    const [addressError, setAddressError] = useState(false);
    const [cityError, setCityError] = useState(false);

    const contactRef = useRef("");
    contactRef.showContactType=true    

    const mutation = useMutation({
        mutationFn: (property) => {
            return authAxios.post("/rentals/properties/", property);
        },
        onError: (error, variables, context) => {
            if (error.response.data.error){
                // If it's an array, display each error message
                if (Array.isArray(error.response.data.error)) {
                    error.response.data.error.map((errorMessage) => (
                        setError(errorMessage)
                    ));
                } else {
                    // Handle single error message
                    setError(error.response.data.error); 
                }
            } else {
                // Fallback to Standard error message
                setError(error.message); 
            }      
        },
        onSuccess: (result) => {
            queryClient.invalidateQueries(['properties']);
            // navigate('/dashboard/properties/')
            setIsModalOpen(false)
        },
    })


    const handleSubmit = (e) => {
        e.preventDefault();

        if (name === '') {
            setNameError(true)
        }

        if (address === '' ) {
            setAddressError(true)
        }

        if (city === '' ) {
            setCityError(true)
        }

        if (name && address && city ) {

            // const location_type = locationType
            // const propType = propertyTypes.find(type => type.code === "30200");
            // const trust_account = trustAccount
            // const control_account = controlAccount

            // const property = { type, client, name, address, city, country, email, trust_account, control_account, user, memo, landlord };
            const management_fee =  managementFee ? parseFloat(managementFee)/100 : null;
            const property = {client, name, address, city, country, user, memo, landlord, management_fee };

            console.log(property)
            mutation.mutate(property)
            
        } 
    
    }

    useEffect(()=>{
        if (error){
            errorRef.current.scrollIntoView();  
        }            
    },[error])

    return(
        <>
        {isModalOpen && 
            <Modal className="dashboard-modal" title="Add Property" visible={isModalOpen} open={isModalOpen} onOk={handleSubmit} onCancel={handleCancel}>
                <div className="dashboard-form">

                    <div ref={errorRef}>
                        <UserAlerts error={error}  />
                    </div>

                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    {/* <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        defaultChecked=""
                        onChange={(e)=>{
                            const propType = propertyTypes.find(type => type.code === e.target.value);
                            // setType(e.target.value)
                            setType(propType.id)
                        }}
                    >
                        <FormControlLabel className="custom-radio" value="010" control={<Radio />} label="Residential" />
                        <FormControlLabel className="custom-radio" value="020" control={<Radio />} label="Commercial" />

                    </RadioGroup> */}

                    <TextField className="custom-input"
                        onChange={(e) => {
                            setNameError(false)
                            setName(e.target.value)
                        }}
                        label="Name" 
                        variant="outlined" 
                        fullWidth
                        required
                        error={nameError}
                    />

                    <TextField className="custom-input"
                        onChange={(e) => {
                            setAddressError(false)
                            setAddress(e.target.value)
                        }}
                        label="Physical Address" 
                        variant="outlined" 
                        fullWidth
                        required
                        error={addressError}
                    />  

                    <TextField className="custom-input"
                        onChange={(e) => {
                            setCityError(false)
                            setCity(e.target.value)
                        }}
                        label="City/Town" 
                        variant="outlined" 
                        fullWidth
                        required
                        error={cityError}
                    />  
                    
                    {clientType==="010" && 
                    <>
                        <Autocomplete
                            onChange={(event, newValue) => {
                                setLandlord(newValue.id);
                                console.log("VALUE", newValue.id)
                                }}
                            options={owners}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Owner"  /> }
                        />

                        <NumberFormat 
                            value = {managementFee}
                            onChange={(e) => {
                                setManagementFee(e.target.value)
                            }}
                            className="custom-input"
                            fullWidth
                            label="Management Fee" 
                            variant="outlined" 
                            customInput={TextField}
                            suffix="%"
                            decimalScale={2}
                            fixedDecimalScale
                            decimalSeparator="."
                            allowNegative={false}
                            isNumericString
                
                        />
                    </>
                    }

                    <TextField className="custom-input"
                        onChange={(e) => setMemo(e.target.value)}
                        label="Memo"
                        variant="outlined"
                        multiline
                        rows={3}
                        fullWidth
                    />
                </form>
                </div>
            </Modal>
        }
        </>

    )

}

export default PropertyNew;
